import { HeaderNavProvider } from 'contexts/HeaderProvider';

import type { GatsbyBrowser } from 'gatsby';
import 'styles/global.css';

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location }) => {
  let floatingCampaign = document.querySelector('.CampaignType--floating');

  const setHeaderPosition = () => {
    floatingCampaign = document.querySelector('.CampaignType--floating');

    const headerContainerEle = document.getElementById('header-container');

    if (headerContainerEle) {
      headerContainerEle.style.top = floatingCampaign.offsetHeight + 'px';
    }
  };

  if (floatingCampaign) {
    setHeaderPosition();
  }

  document.addEventListener('om.Campaign.afterShow', () => {
    setHeaderPosition();
  });

  if (!location.hash) {
    window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
  }
};

export const wrapPageElement = ({ element }) => <HeaderNavProvider>{element}</HeaderNavProvider>;
