import { Dispatch, FC, ReactNode, SetStateAction, createContext, useContext, useState } from 'react';

interface HeaderNavContextProps {
  selectedContentType: string | null;
  setSelectedContentType: Dispatch<SetStateAction<string | null>>;
}

const HeaderNavContext = createContext<HeaderNavContextProps>({
  selectedContentType: null,
  setSelectedContentType: () => null,
});

interface HeaderNavProviderProps {
  children: ReactNode;
}

export const HeaderNavProvider: FC<HeaderNavProviderProps> = ({ children }) => {
  const [selectedContentType, setSelectedContentType] = useState<string | null>(null);

  return (
    <HeaderNavContext.Provider value={{ selectedContentType, setSelectedContentType }}>
      {children}
    </HeaderNavContext.Provider>
  );
};

export const useHeaderNav = () => useContext(HeaderNavContext);

export default HeaderNavContext;
